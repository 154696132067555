import {
  fortnoxServiceBaseURL,
  powerOfficeServiceBaseURL,
} from "@/services/agent";

export default class EndpointResolver {
  constructor() {}

  getMasterLogDetailsEndpoint(moduleId: number): string {
    let endpoint = "";

    if (moduleId == 2) {
      // Fortnox
      endpoint = `${fortnoxServiceBaseURL}v1/SyncLogDetails/GetSyncLogDetails`;
    }

    if (moduleId == 5) {
      endpoint = `${powerOfficeServiceBaseURL}v1/SyncLogDetails/GetSyncLogDetails`;
    }

    return endpoint;
  }

  getMasterLogEndpoint(moduleId: number): string {
    let endpoint = "";

    if (moduleId == 2) {
      // Fortnox
      endpoint = `${fortnoxServiceBaseURL}v1/SyncLogMaster/GetSyncLogMaster`;
    }

    if (moduleId == 5) {
      endpoint = `${powerOfficeServiceBaseURL}v1/SyncLogMaster/GetSyncLogMaster`;
    }

    return endpoint;
  }
}
